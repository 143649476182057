import type { ReactNode } from "preact/compat"
import { Badge, Card, HiddenVisually, Radio, Text, View } from "reshaped"
import css from "./cardRadio.module.css"

type CardRadioProps = {
	isSelected: boolean
	isDisabled?: boolean
	value: string
	name: string
	icon?: ReactNode
	badge?: string
	onClick?: () => void
}
export const CardRadio: React.FunctionComponent<CardRadioProps> = (props) => {
	return (
		<View.Item columns={3}>
			<Card
				onClick={props.onClick}
				attributes={{
					style: {
						color: props.isDisabled
							? "var(--rs-color-border-neutral-faded)"
							: "var(--rs-color-on-background-neutral)",
						cursor: props.isDisabled ? "not-allowed" : "pointer",
						position: "relative",
						overflow: "inherit",
						zIndex: 0,
					},
				}}
				as="label"
				height="100%"
				padding={5}
				selected={props.isSelected}
			>
				{props.badge && (
					<Badge
						className={css.badge}
						color={props.isDisabled ? "neutral" : "primary"}
						size="small"
					>
						{props.badge}
					</Badge>
				)}
				<HiddenVisually>
					<Radio value={props.value} />
				</HiddenVisually>
				<View direction="row" align="center" gap={2}>
					{props.icon && props.icon}
					<Text variant="body-2" weight="medium">
						{props.name}
					</Text>
				</View>
			</Card>
		</View.Item>
	)
}
