import { observer } from "mobx-react"
import { useEffect, useState } from "preact/hooks"
import { GitHub, Info, Trash2 } from "react-feather"
import {
	Button,
	FormControl,
	Icon,
	RadioGroup,
	Select,
	Text,
	TextField,
	Tooltip,
	View,
} from "reshaped"
import AmazonS3 from "../../../../public/icons/amazonS3.svg?react"
import Clickhouse from "../../../../public/icons/clickhouse.svg?react"
import Pinecone from "../../../../public/icons/pinecone.svg?react"
import Snowflake from "../../../../public/icons/snowflake.svg?react"
import Webhook from "../../../../public/icons/webhook.svg?react"
import { CardRadio } from "../../../components/PipelineCreation/CardRadio/component"
import { pipelineCreatePageStore } from "../../PipelineCreate/page.model"
import { pipelineDetailPageStore } from "../page.model"

export const SinkOptions = [
	{ value: "sdk", name: "SDK", icon: <Icon size={5} svg={<GitHub />} /> },
	{
		value: "webhook",
		name: "Webhook",
		badge: "managed",
		icon: (
			<Webhook
				height="24px"
				style={{
					color: "var(--rs-color-on-background-neutral)",
				}}
			/>
		),
	},
	{
		value: "clickhouse",
		name: "ClickHouse",
		badge: "managed",
		icon: (
			<Clickhouse
				height="19px"
				style={{
					color: "var(--rs-color-on-background-neutral)",
				}}
			/>
		),
	},
	{
		value: "amazon_s3",
		name: "Amazon S3",
		badge: "managed",
		icon: (
			<AmazonS3
				height="19px"
				style={{
					color: "var(--rs-color-on-background-neutral)",
				}}
			/>
		),
	},
	{
		value: "pinecone_json",
		name: "Pinecone",
		badge: "managed",
		icon: (
			<Pinecone
				height="19px"
				style={{
					color: "var(--rs-color-on-background-neutral)",
				}}
			/>
		),
	},
	{
		value: "snowflake_cdc_json",
		name: "Snowflake",
		badge: "managed",
		icon: (
			<Snowflake
				height="19px"
				style={{
					color: "var(--rs-color-on-background-neutral)",
				}}
			/>
		),
	},
]

export const ManagedSinks = SinkOptions.filter(
	(source) => source.badge === "managed",
)

export const UnmanagedSinks = SinkOptions.filter((sink) => !sink.badge)

interface DataSinkProps {
	isEdit: boolean
	onDiscard: () => void
	onSave: () => void
}

export const DataSink: React.FC<DataSinkProps> = observer(
	({ isEdit, onDiscard, onSave }) => {
		let details: React.JSX.Element | null = null
		const store = pipelineCreatePageStore
		const store_detail = pipelineDetailPageStore
		const [changeHappens, setChangeHappens] = useState(false)

		useEffect(() => {
			store.sinkForm.setType(store_detail.sinkConnectorType)
		}, [store.sinkForm, store_detail.sinkConnectorType])

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		useEffect(() => {
			if (store_detail.sinkConnectorType === "clickhouse") {
				if (store_detail.sinkConnectorValues) {
					store.sinkForm.setClickhouseConnector(
						store_detail.sinkConnectorValues as {
							addr: string
							database: string
							username: string
							password: string
							table: string
						},
					)
				}
			} else if (store_detail.sinkConnectorType === "amazon_s3") {
				if (store_detail.sinkConnectorValues) {
					store.sinkForm.setAmazonS3Connector(
						store_detail.sinkConnectorValues as {
							s3_bucket: string
							s3_key: string
							aws_region: string
							aws_access_key: string
							aws_secret_key: string
						},
					)
				}
			} else if (
				store_detail.sinkConnectorType === "snowflake_cdc_json"
			) {
				if (store_detail.sinkConnectorValues) {
					store.sinkForm.setSnowflakeConnector(
						store_detail.sinkConnectorValues as {
							account: string
							warehouse: string
							db_user: string
							db_pass: string
							db_name: string
							db_schema: string
							db_host: string
							db_port: string
							db_role: string
						},
					)
				}
			} else if (store_detail.sinkConnectorType === "pinecone_json") {
				if (store_detail.sinkConnectorValues) {
					store.sinkForm.setPineconeConnector(
						store_detail.sinkConnectorValues as {
							api_key: string
							api_host: string
							index_host: string
							api_source_tag: string
							client_headers: string
						},
					)
				}
			} else if (store_detail.sinkConnectorType === "webhook") {
				if (store_detail.sinkConnectorValues) {
					store.sinkForm.setWebhookConnector(
						store_detail.sinkConnectorValues as {
							url: string
							method: "GET" | "POST" | "PUT" | "PATCH"
							headers: Record<string, string>
						},
					)
				}
			}
		}, [store.sinkForm.connector.type])

		if (store.sinkForm.connector.type === "webhook") {
			let headers = (
				<>
					<View paddingBottom={1}>
						<Text variant="body-3" weight="medium">
							Headers
						</Text>
					</View>

					<View gap={4}>
						{store.sinkForm.connector.meta.headers.map(
							({ name, value }, index) => {
								return (
									// biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
									<View gap={4}>
										<View gap={4} direction="row">
											<View direction="row" grow gap={4}>
												<View.Item columns={6}>
													<TextField
														name={`headerKey${index}`}
														placeholder="Name"
														value={name}
														onChange={({
															value,
														}) => {
															if (
																store.sinkForm
																	.connector
																	.type ===
																"webhook"
															) {
																store.sinkForm.connector.meta.setHeaderKey(
																	index,
																	value,
																)
																setChangeHappens(
																	true,
																)
															}
														}}
													/>
												</View.Item>
												<View.Item columns={6}>
													<TextField
														name={`headerValue${index}`}
														placeholder="Value"
														value={value}
														onChange={({
															value,
														}) => {
															if (
																store.sinkForm
																	.connector
																	.type ===
																"webhook"
															) {
																store.sinkForm.connector.meta.setHeaderValue(
																	index,
																	value,
																)
																setChangeHappens(
																	true,
																)
															}
														}}
													/>
												</View.Item>
											</View>

											<Button
												icon={Trash2}
												color="critical"
												variant="outline"
												onClick={() => {
													if (
														store.sinkForm.connector
															.type === "webhook"
													) {
														store.sinkForm.connector.meta.removeHeader(
															index,
														)
														setChangeHappens(true)
													}
												}}
											/>
										</View>
									</View>
								)
							},
						)}

						<View.Item columns={3}>
							<Button
								fullWidth
								color="positive"
								variant="faded"
								onClick={() => {
									if (
										store.sinkForm.connector.type ===
										"webhook"
									) {
										store.sinkForm.connector.meta.addHeader()
										setChangeHappens(true)
									}
								}}
							>
								Add Header
							</Button>
						</View.Item>
					</View>
				</>
			)

			details = (
				<View gap={4} direction="column" paddingTop={6} key="webhook">
					{!pipelineDetailPageStore.viewOnlyMode && (
						<Text variant="body-2">
							Use GlassFlow managed Webhook connector. GlassFlow
							will publish transformed data automatically to the
							Webhook URL defined below.
						</Text>
					)}
					<Text variant="title-6">Connector Details</Text>

					<View direction="row" wrap gap={4}>
						<View.Item columns={2}>
							<FormControl>
								<FormControl.Label>Method</FormControl.Label>
								<Select
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									options={[
										{ label: "POST", value: "POST" },
										{ label: "GET", value: "GET" },
										{ label: "PUT", value: "PUT" },
										{ label: "PATCH", value: "PATCH" },
									]}
									name="method"
									value={store.sinkForm.connector.meta.method}
									onChange={(event) => {
										if (
											store.sinkForm.connector.type ===
											"webhook"
										) {
											store.sinkForm.connector.meta.setMethod(
												event.value as
													| "POST"
													| "GET"
													| "PUT"
													| "PATCH",
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{
										"data-1p-ignore": true,
									}}
								/>
							</FormControl>
						</View.Item>

						<View.Item columns={10}>
							<FormControl
								hasError={
									store.sinkForm.connector.meta.url.hasError
								}
							>
								<FormControl.Label>URL</FormControl.Label>
								<TextField
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									name="url"
									value={
										store.sinkForm.connector.meta.url.value
									}
									onChange={(event) => {
										if (
											store.sinkForm.connector.type ===
											"webhook"
										) {
											store.sinkForm.connector.meta.setUrl(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{
										"data-1p-ignore": true,
									}}
								/>
								<FormControl.Error>
									{
										store.sinkForm.connector.meta.url
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={12}>{headers}</View.Item>
					</View>
				</View>
			)
		} else if (store.sinkForm.connector.type === "clickhouse") {
			details = (
				<View gap={4} direction="column" paddingTop={6} key="webhook">
					{!pipelineDetailPageStore.viewOnlyMode && (
						<Text variant="body-2">
							Use GlassFlow managed ClickHouse connector.
							GlassFlow will publish transformed data
							automatically to your ClickHouse database.
						</Text>
					)}
					<Text variant="title-6">Connector Details</Text>
					<View direction="row" wrap gap={4}>
						<View.Item columns={12}>
							<FormControl
								hasError={
									store.sinkForm.connector.meta.addr.hasError
								}
							>
								<FormControl.Label>Address</FormControl.Label>
								<TextField
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									name="addr"
									value={
										store.sinkForm.connector.meta.addr.value
									}
									onChange={(event) => {
										if (
											store.sinkForm.connector.type ===
											"clickhouse"
										) {
											store.sinkForm.connector.meta.setAddress(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{
										"data-1p-ignore": true,
									}}
								/>
								<FormControl.Error>
									{
										store.sinkForm.connector.meta.addr
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sinkForm.connector.meta.database
										.hasError
								}
							>
								<FormControl.Label>Database</FormControl.Label>
								<TextField
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									name="database"
									value={
										store.sinkForm.connector.meta.database
											.value
									}
									onChange={(event) => {
										if (
											store.sinkForm.connector.type ===
											"clickhouse"
										) {
											store.sinkForm.connector.meta.setDatabase(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{
										"data-1p-ignore": true,
									}}
								/>
								<FormControl.Error>
									{
										store.sinkForm.connector.meta.database
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sinkForm.connector.meta.table.hasError
								}
							>
								<FormControl.Label>Table</FormControl.Label>
								<TextField
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									name="table"
									value={
										store.sinkForm.connector.meta.table
											.value
									}
									onChange={(event) => {
										if (
											store.sinkForm.connector.type ===
											"clickhouse"
										) {
											store.sinkForm.connector.meta.setTable(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{
										"data-1p-ignore": true,
									}}
								/>
								<FormControl.Error>
									{
										store.sinkForm.connector.meta.table
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sinkForm.connector.meta.username
										.hasError
								}
							>
								<FormControl.Label>User Name</FormControl.Label>
								<TextField
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									name="username"
									value={
										store.sinkForm.connector.meta.username
											.value
									}
									onChange={(event) => {
										if (
											store.sinkForm.connector.type ===
											"clickhouse"
										) {
											store.sinkForm.connector.meta.setUsername(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{
										"data-1p-ignore": true,
									}}
								/>
								<FormControl.Error>
									{
										store.sinkForm.connector.meta.username
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sinkForm.connector.meta.password
										.hasError
								}
							>
								<FormControl.Label>Password</FormControl.Label>
								<TextField
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									name="password"
									value={
										store.sinkForm.connector.meta.password
											.value
									}
									onChange={(event) => {
										if (
											store.sinkForm.connector.type ===
											"clickhouse"
										) {
											store.sinkForm.connector.meta.setPassword(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{
										"data-1p-ignore": true,
										type: "password",
									}}
								/>
								<FormControl.Error>
									{
										store.sinkForm.connector.meta.password
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>
					</View>
				</View>
			)
		} else if (store.sinkForm.connector.type === "amazon_s3") {
			details = (
				<View gap={4} direction="column" paddingTop={6} key="webhook">
					{!pipelineDetailPageStore.viewOnlyMode && (
						<Text variant="body-2">
							Use GlassFlow managed Amazon S3 connector. GlassFlow
							will publish transformed data automatically to your
							S3 file.
						</Text>
					)}
					<Text variant="title-6">Connector Details</Text>
					<View direction="row" wrap gap={4}>
						<View.Item columns={12}>
							<FormControl
								hasError={
									store.sinkForm.connector.meta.s3_bucket
										.hasError
								}
							>
								<FormControl.Label>
									Bucket Name
								</FormControl.Label>
								<TextField
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									name="s3_bucket"
									value={
										store.sinkForm.connector.meta.s3_bucket
											.value
									}
									onChange={(event) => {
										if (
											store.sinkForm.connector.type ===
											"amazon_s3"
										) {
											store.sinkForm.connector.meta.setBucket(
												event.value,
											)
										}
										setChangeHappens(true)
									}}
									inputAttributes={{}}
								/>
								<FormControl.Error>
									{
										store.sinkForm.connector.meta.s3_bucket
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sinkForm.connector.meta.s3_key
										.hasError
								}
							>
								<FormControl.Label>
									Folder Name
								</FormControl.Label>
								<TextField
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									name="s3_key"
									value={
										store.sinkForm.connector.meta.s3_key
											.value
									}
									onChange={(event) => {
										if (
											store.sinkForm.connector.type ===
											"amazon_s3"
										) {
											store.sinkForm.connector.meta.setKey(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{}}
								/>
								<FormControl.Error>
									{
										store.sinkForm.connector.meta.s3_key
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sinkForm.connector.meta.region
										.hasError
								}
							>
								<FormControl.Label>
									AWS Region
								</FormControl.Label>
								<TextField
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									name="region"
									value={
										store.sinkForm.connector.meta.region
											.value
									}
									onChange={(event) => {
										if (
											store.sinkForm.connector.type ===
											"amazon_s3"
										) {
											store.sinkForm.connector.meta.setRegion(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{}}
								/>
								<FormControl.Error>
									{
										store.sinkForm.connector.meta.region
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sinkForm.connector.meta.accessKey
										.hasError
								}
							>
								<FormControl.Label>
									AWS Access Key
								</FormControl.Label>
								<TextField
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									name="accessKey"
									value={
										store.sinkForm.connector.meta.accessKey
											.value
									}
									onChange={(event) => {
										if (
											store.sinkForm.connector.type ===
											"amazon_s3"
										) {
											store.sinkForm.connector.meta.setAccessKey(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{}}
								/>
								<FormControl.Error>
									{
										store.sinkForm.connector.meta.accessKey
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sinkForm.connector.meta.secretKey
										.hasError
								}
							>
								<FormControl.Label>
									AWS Secret Key
								</FormControl.Label>
								<TextField
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									name="secretKey"
									value={
										store.sinkForm.connector.meta.secretKey
											.value
									}
									onChange={(event) => {
										if (
											store.sinkForm.connector.type ===
											"amazon_s3"
										) {
											store.sinkForm.connector.meta.setSecretKey(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{}}
								/>
								<FormControl.Error>
									{
										store.sinkForm.connector.meta.secretKey
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>
					</View>
				</View>
			)
		} else if (store.sinkForm.connector.type === "snowflake_cdc_json") {
			details = (
				<View
					gap={4}
					direction="column"
					paddingTop={6}
					key="snowflake_cdc_json"
				>
					{!pipelineDetailPageStore.viewOnlyMode && (
						<Text variant="body-2">
							Provide your connector details, and we will publish
							transformed data automatically to your Snowflake
							database.
						</Text>
					)}
					<Text variant="title-6">Connector Details</Text>
					<View direction="row" wrap gap={4}>
						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sinkForm.connector.meta.account
										.hasError
								}
							>
								<FormControl.Label>Account</FormControl.Label>
								<TextField
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									name="account"
									value={
										store.sinkForm.connector.meta.account
											.value
									}
									onChange={(event) => {
										if (
											store.sinkForm.connector.type ===
											"snowflake_cdc_json"
										) {
											store.sinkForm.connector.meta.setAccount(
												event.value,
											)
											setChangeHappens(true)
										}
										setChangeHappens(true)
									}}
									inputAttributes={{}}
								/>
								<FormControl.Error>
									{
										store.sinkForm.connector.meta.account
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sinkForm.connector.meta.warehouse
										.hasError
								}
							>
								<FormControl.Label>Warehouse</FormControl.Label>
								<TextField
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									name="warehouse"
									value={
										store.sinkForm.connector.meta.warehouse
											.value
									}
									onChange={(event) => {
										if (
											store.sinkForm.connector.type ===
											"snowflake_cdc_json"
										) {
											store.sinkForm.connector.meta.setWarehouse(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{}}
								/>
								<FormControl.Error>
									{
										store.sinkForm.connector.meta.warehouse
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sinkForm.connector.meta.db_user
										.hasError
								}
							>
								<FormControl.Label>User Name</FormControl.Label>
								<TextField
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									name="db_user"
									value={
										store.sinkForm.connector.meta.db_user
											.value
									}
									onChange={(event) => {
										if (
											store.sinkForm.connector.type ===
											"snowflake_cdc_json"
										) {
											store.sinkForm.connector.meta.setDBUser(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{}}
								/>
								<FormControl.Error>
									{
										store.sinkForm.connector.meta.db_user
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sinkForm.connector.meta.db_pass
										.hasError
								}
							>
								<FormControl.Label>Password</FormControl.Label>
								<TextField
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									name="accessKey"
									value={
										store.sinkForm.connector.meta.db_pass
											.value
									}
									onChange={(event) => {
										if (
											store.sinkForm.connector.type ===
											"snowflake_cdc_json"
										) {
											store.sinkForm.connector.meta.setDBPass(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{
										"data-1p-ignore": true,
										type: "password",
									}}
								/>
								<FormControl.Error>
									{
										store.sinkForm.connector.meta.db_pass
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sinkForm.connector.meta.db_name
										.hasError
								}
							>
								<FormControl.Label>Database</FormControl.Label>
								<TextField
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									name="db_name"
									value={
										store.sinkForm.connector.meta.db_name
											.value
									}
									onChange={(event) => {
										if (
											store.sinkForm.connector.type ===
											"snowflake_cdc_json"
										) {
											store.sinkForm.connector.meta.setDBName(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{}}
								/>
								<FormControl.Error>
									{
										store.sinkForm.connector.meta.db_name
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sinkForm.connector.meta.db_schema
										.hasError
								}
							>
								<FormControl.Label>Schema</FormControl.Label>
								<TextField
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									name="db_schema"
									value={
										store.sinkForm.connector.meta.db_schema
											.value
									}
									onChange={(event) => {
										if (
											store.sinkForm.connector.type ===
											"snowflake_cdc_json"
										) {
											store.sinkForm.connector.meta.setDBSchema(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{}}
								/>
								<FormControl.Error>
									{
										store.sinkForm.connector.meta.db_schema
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={6}>
							<FormControl>
								<FormControl.Label>
									Role (optional)
								</FormControl.Label>
								<TextField
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									name="db_role"
									value={
										store.sinkForm.connector.meta.db_role
									}
									onChange={(event) => {
										if (
											store.sinkForm.connector.type ===
											"snowflake_cdc_json"
										) {
											store.sinkForm.connector.meta.setDBRole(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{}}
								/>
							</FormControl>
						</View.Item>
					</View>
				</View>
			)
		} else if (store.sinkForm.connector.type === "pinecone_json") {
			details = (
				<View
					gap={4}
					direction="column"
					paddingTop={6}
					key="pinecone_json"
				>
					{!pipelineDetailPageStore.viewOnlyMode && (
						<Text variant="body-2">
							Provide your connector details, and we will publish
							transformed data automatically to your Pinecone
							database.
						</Text>
					)}
					<Text variant="title-6">Connector Details</Text>
					<View direction="row" wrap gap={4}>
						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sinkForm.connector.meta.api_key
										.hasError
								}
							>
								<FormControl.Label>API Key</FormControl.Label>
								<TextField
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									name="api_key"
									value={
										store.sinkForm.connector.meta.api_key
											.value
									}
									onChange={(event) => {
										if (
											store.sinkForm.connector.type ===
											"pinecone_json"
										) {
											store.sinkForm.connector.meta.setAPIKey(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{}}
								/>
								<FormControl.Error>
									{
										store.sinkForm.connector.meta.api_key
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sinkForm.connector.meta.api_host
										.hasError
								}
							>
								<FormControl.Label>API Host</FormControl.Label>
								<TextField
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									name="api_host"
									value={
										store.sinkForm.connector.meta.api_host
											.value
									}
									onChange={(event) => {
										if (
											store.sinkForm.connector.type ===
											"pinecone_json"
										) {
											store.sinkForm.connector.meta.setAPIHost(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{}}
								/>
								<FormControl.Error>
									{
										store.sinkForm.connector.meta.api_host
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sinkForm.connector.meta.index_host
										.hasError
								}
							>
								<FormControl.Label>
									Index Host
								</FormControl.Label>
								<TextField
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									name="index_host"
									value={
										store.sinkForm.connector.meta.index_host
											.value
									}
									onChange={(event) => {
										if (
											store.sinkForm.connector.type ===
											"pinecone_json"
										) {
											store.sinkForm.connector.meta.setIndexHost(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{}}
								/>
								<FormControl.Error>
									{
										store.sinkForm.connector.meta.index_host
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={6}>
							<FormControl>
								<FormControl.Label>
									API Source Tag (optional)
								</FormControl.Label>
								<TextField
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									name="api_source_tag"
									value={
										store.sinkForm.connector.meta
											.api_source_tag
									}
									onChange={(event) => {
										if (
											store.sinkForm.connector.type ===
											"pinecone_json"
										) {
											store.sinkForm.connector.meta.setAPISourceTag(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{}}
								/>
							</FormControl>
						</View.Item>
						{/*
						<View.Item columns={6}>
							<FormControl>
								<FormControl.Label>
									Client Headers (optional)
								</FormControl.Label>
								<TextField
									name="client_headers"
									value={
										store.sinkForm.connector.meta
											.client_headers
									}
									onChange={(event) => {
										if (
											store.sinkForm.connector.type ===
											"pinecone_json"
										) {
											store.sinkForm.connector.meta.setClientHeaders(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{}}
								/>
							</FormControl>
						</View.Item> */}
					</View>
				</View>
			)
		} else if (store.sinkForm.connector.type === "sdk") {
			details = (
				<View gap={4} direction="column" paddingTop={6} key="sdk">
					{!isEdit ? (
						<Text variant="body-2">
							After creating the pipeline, head to our{" "}
							<a
								href="https://github.com/glassflow/glassflow-examples/tree/main/usage"
								target="_blank"
								rel="noopener noreferrer"
								style={{
									color: "#FFA24B",
									textDecoration: "none",
								}}
							>
								GitHub repo
							</a>{" "}
							to see how to use the Python SDK to consume data.
						</Text>
					) : (
						<Text variant="body-2">
							Head to our{" "}
							<a
								href="https://github.com/glassflow/glassflow-examples/tree/main/usage"
								target="_blank"
								rel="noopener noreferrer"
								style={{
									color: "#FFA24B",
									textDecoration: "none",
								}}
							>
								GitHub repo
							</a>{" "}
							to see how to use the Python SDK to consume data.
						</Text>
					)}
				</View>
			)
		}

		const saveSink = async () => {
			if ((await store.isSinkFormValid()) && store_detail.pipeline?.id) {
				await pipelineDetailPageStore.patchSourceAndSink(
					store_detail.pipeline.id,
					"sink",
				)
				onSave()
			}
		}

		const continueButton = (
			<View paddingTop={8}>
				<Button
					color="primary"
					loading={pipelineDetailPageStore.isSaving}
					onClick={saveSink}
				>
					Continue
				</Button>
			</View>
		)

		const discardAndSaveBtn = (
			<View grow align="end">
				<View direction="row" gap={3}>
					<Button
						color="neutral"
						disabled={false}
						onClick={onDiscard}
					>
						Discard
					</Button>
					<Button
						variant="solid"
						color="primary"
						onClick={saveSink}
						disabled={
							pipelineDetailPageStore.viewOnlyMode ||
							(pipelineDetailPageStore.sinkConnectorType ===
								pipelineCreatePageStore.sinkForm.connector
									.type &&
								!changeHappens)
						}
						loading={pipelineDetailPageStore.isSaving}
					>
						Save Changes
					</Button>
				</View>
			</View>
		)

		return (
			<>
				<View>
					<View>
						<View paddingBottom={6} direction="row" align="center">
							<Text variant="title-5">Set Up Data Sink </Text>
							<Tooltip
								text={
									<>
										Select a data sink to consume data from
										the pipeline. <br />
										<a
											href="https://www.glassflow.dev/docs/concepts/data-sink"
											target="_blank"
											rel="noopener noreferrer"
											style={{
												color: "#FFA24B",
												textDecoration: "underline",
											}}
										>
											Learn more
										</a>
									</>
								}
								position="end"
							>
								{(attributes) => (
									<Button
										variant="ghost"
										icon={Info}
										attributes={attributes}
									/>
								)}
							</Tooltip>
							{isEdit && discardAndSaveBtn}
						</View>
						{!pipelineDetailPageStore.viewOnlyMode && (
							<>
								<div
									style={{
										display: "flex",
										marginBottom: "8px",
									}}
								>
									<div>
										<Text
											variant="body-2"
											attributes={{
												style: { color: "#a8adb8" },
											}}
										>
											Self-managed
										</Text>
									</div>
								</div>

								<View direction="row" align="stretch" gap={4}>
									<RadioGroup name="sink">
										{UnmanagedSinks.map((option) => (
											<CardRadio
												key={option.value}
												onClick={() =>
													store.sinkForm.setType(
														option.value as "sdk",
													)
												}
												isSelected={
													store.sinkForm.connector
														.type === option.value
												}
												value={option.value}
												name={option.name}
												// badge={option.badge}
												icon={option.icon}
											/>
										))}
									</RadioGroup>
								</View>
								<div
									style={{
										paddingTop: "10px",
										paddingBottom: "6px",
									}}
								>
									<Text
										variant="body-2"
										attributes={{
											style: { color: "#a8adb8" },
										}}
									>
										Managed
									</Text>
								</div>

								<View direction="row" align="stretch" gap={4}>
									<RadioGroup name="sink">
										{ManagedSinks.map((option) => (
											<CardRadio
												key={option.value}
												onClick={() =>
													store.sinkForm.setType(
														option.value as
															| "webhook"
															| "clickhouse"
															| "amazon_s3"
															| "snowflake_cdc_json"
															| "pinecone_json",
													)
												}
												isSelected={
													store.sinkForm.connector
														.type === option.value
												}
												value={option.value}
												name={option.name}
												// badge={option.badge}
												icon={option.icon}
											/>
										))}
									</RadioGroup>
								</View>
							</>
						)}
						{details}
					</View>
					{!isEdit && continueButton}
				</View>
			</>
		)
	},
)
