import * as Fathom from "fathom-client"
import { type Instance, cast, flow, getSnapshot, types } from "mobx-state-tree"

import { AccessTokenModel, LogModel, PipelineModel, api } from "#api"
import { router } from "#router"
import { PipelineCreateType } from "../../enums"
import type { EnvironmentVariable } from "../../modals/EditEnvironmentVariables/modal.model"
import { pipelineCreatePageStore } from "../PipelineCreate/page.model"

const ResponseObj: Record<string, unknown> = {}
const AccessTokensModel = types
	.model("Access Tokens", {
		pipeline: types.maybe(types.reference(PipelineModel)),
		tokens: types.array(AccessTokenModel),
		isFetchingTokens: types.optional(types.boolean, false),
	})
	.actions((self) => {
		const getTokens = flow(function* () {
			if (self.pipeline === undefined) {
				return
			}

			self.isFetchingTokens = true

			let tokens: Awaited<ReturnType<typeof api.listAccessTokens>> =
				yield api.listAccessTokens({
					queries: {
						page_size: 1000,
					},
					params: {
						pipeline_id: self.pipeline.id,
					},
				})

			self.tokens = cast(tokens.access_tokens)

			self.isFetchingTokens = false
		})

		return {
			getTokens,
		}
	})
	.views((self) => {
		return {
			get hasTokens() {
				return self.tokens.length > 0
			},
		}
	})

// #endregion

// #region Transformer

const TransformerModel = types
	.model("Transformer", {
		form: types.optional(
			types
				.model("Form", {
					currentHandler: types.optional(types.string, ""),
					currentRequirements: types.optional(types.string, ""),
					currentTestEvent: types.optional(types.string, ""),
					currentTestEventResponse: types.optional(types.string, ""),
					handler: types.optional(
						types.model("file", {
							value: types.optional(types.string, ""),
							hasError: types.optional(types.boolean, false),
							errorMessage: types.optional(types.string, ""),
						}),
						{},
					),
					requirements: types.optional(
						types.model("file", {
							value: types.optional(types.string, ""),
							hasError: types.optional(types.boolean, false),
							errorMessage: types.optional(types.string, ""),
						}),
						{},
					),
					testEvent: types.optional(
						types.model("file", {
							value: types.optional(types.string, ""),
							hasError: types.optional(types.boolean, false),
							errorMessage: types.optional(types.string, ""),
						}),
						{},
					),
					testEventResponse: types.optional(
						types.model("file", {
							value: types.optional(types.string, ""),
							hasError: types.optional(types.boolean, false),
							errorMessage: types.optional(types.string, ""),
						}),
						{},
					),
					environmentVariables: types.optional(
						types.array(
							types.model({
								name: types.string,
								value: types.string,
							}),
						),
						[{ name: "", value: "" }],
					),
					currentEnvironmentVariables: types.optional(
						types.array(
							types.model({
								name: types.string,
								value: types.string,
							}),
						),
						[{ name: "", value: "" }],
					),
				})
				.actions((self) => {
					return {
						validate() {
							if (
								self.handler.value === "" ||
								self.handler.value === undefined
							) {
								self.handler.hasError = true
								self.handler.errorMessage =
									"This field is required"
							} else {
								// Parse the Editor contents for a top level definition
								// of a function with the name `handler`
								let hasHandlerFunction = false

								const lines = self.handler.value.split("\n")

								for (let line of lines) {
									if (line.startsWith("def")) {
										if (
											line
												.slice(3)
												.trim()
												.startsWith("handler") &&
											line
												.slice(3)
												.trim()
												.slice(7)
												.trim()
												.startsWith("(")
										) {
											hasHandlerFunction = true
											break
										}
									}
								}

								if (!hasHandlerFunction) {
									self.handler.hasError = true
									self.handler.errorMessage =
										"You need to define the `handler` function."
								}
							}
						},
						setRequirements(requirements: string) {
							self.requirements.value = requirements
							self.requirements.hasError = false
						},
						setCurrentRequirements(requirements: string) {
							self.currentRequirements = requirements
						},
						setTestEvent(testEvent: string) {
							self.testEvent.value = testEvent
							self.testEvent.hasError = false
						},
						setCurrentTestEvent(testEvent: string) {
							self.currentTestEvent = testEvent
						},
						setTestEventResponse(testEvent: string) {
							self.testEventResponse.value = testEvent
							self.testEventResponse.hasError = false
							self.testEventResponse.errorMessage = ""
						},
						setCurrentTestEventResponse(testEventResponse: string) {
							self.currentTestEventResponse = testEventResponse
						},
						setHandler(handler: string) {
							self.handler.value = handler
							self.handler.hasError = false
						},
						setCurrentHandler(handler: string) {
							self.currentHandler = handler
						},
						setTestEventErrorMesage(msg: string) {
							self.testEvent.hasError = true
							self.testEvent.errorMessage = msg
						},
						setEnvironmentVariables(
							environmentVariables: Array<{
								name: string
								value: string
							}> | null,
						) {
							if (environmentVariables === null) {
								self.environmentVariables = cast([
									{ name: "", value: "" },
								])
							} else {
								self.environmentVariables =
									cast(environmentVariables)
							}
						},
						setCurrentEnvironmentVariables(
							environmentVariables: Array<{
								name: string
								value: string
							}> | null,
						) {
							if (environmentVariables === null) {
								self.currentEnvironmentVariables = cast([
									{ name: "", value: "" },
								])
							} else {
								self.currentEnvironmentVariables =
									cast(environmentVariables)
							}
						},
					}
				})
				.views((self) => {
					return {
						get hasError() {
							return self.handler.hasError
						},

						get hasChanges() {
							return (
								self.handler.value !== self.currentHandler ||
								self.requirements.value !==
									self.currentRequirements ||
								JSON.stringify(
									getSnapshot(self.environmentVariables),
								) !==
									JSON.stringify(
										getSnapshot(
											self.currentEnvironmentVariables,
										),
									)
							)
						},

						get hasEventChanges() {
							return (
								self.testEvent.value !== self.currentTestEvent
							)
						},
					}
				}),
			{},
		),
		editorSettings: types.optional(
			types
				.model({
					showInvisibles: types.optional(types.boolean, false),
					softWrap: types.optional(types.boolean, true),
				})
				.actions((self) => {
					return {
						setShowInvisibles(value: boolean) {
							self.showInvisibles = value
						},

						setSoftWrap(value: boolean) {
							self.softWrap = value
						},
					}
				}),
			{},
		),
	})
	.actions((self) => {
		const fetchSourceFiles = flow(function* (pipelineId: string) {
			const result: Awaited<ReturnType<typeof api.getArtifact>> =
				yield api.getArtifact({
					params: {
						pipeline_id: pipelineId,
					},
				})

			if (result.files.length > 0) {
				self.form.setCurrentHandler(result.files[0].content)
				self.form.setHandler(result.files[0].content)
			} else {
				self.form.setCurrentHandler("")
				self.form.setHandler("")
			}

			if (result.files.length > 1) {
				const requirements = result.files[1].content

				self.form.setCurrentRequirements(requirements)
				self.form.setRequirements(requirements)
			} else {
				self.form.setCurrentRequirements("")
				self.form.setRequirements("")
			}
		})

		return {
			fetchSourceFiles,
		}
	})

// #endregion

// #region Logs

const LogsModel = types
	.model("Logs", {
		pipeline: types.maybe(types.reference(PipelineModel)),
		cursor: types.maybe(
			types.model("Cursor", {
				cursor: types.string,
				originalCursorTime: types.number,
			}),
		),
		logs: types.array(LogModel),
		fullscreenLogIndex: types.maybe(types.number),
		fetch: types.optional(
			types.union(
				types.model({ isFetching: types.literal(false) }),
				types.model({
					isFetching: types.literal(true),
					fetchingStyle: types.union(
						types.literal("filterChange"),
						types.literal("navigation"),
						types.literal("nextPage"),
						types.literal("autoRefresh"),
					),
				}),
			),
			{ isFetching: false },
		),
		lastFetch: types.optional(types.maybeNull(types.number), null),
		lastFetchWasForced: types.optional(types.boolean, false),
		filters: types.optional(
			types
				.model({
					severityLevel: types.optional(
						types.union(
							types.literal(100),
							types.literal(200),
							types.literal(400),
							types.literal(500),
						),
						200,
					),
					timeframe: types.optional(
						types.union(
							types.literal("oneHour"),
							types.literal("sixHours"),
							types.literal("oneDay"),
							types.literal("sevenDays"),
						),
						"oneHour",
					),
				})
				.actions((self) => {
					return {
						setSeverityLevel(level: 100 | 200 | 400 | 500) {
							Fathom.trackEvent(
								"Pipeline:Logs Filter:Severity Set",
							)
							Fathom.trackEvent(
								`Pipeline:Logs Filter:Severity Set=${level}`,
							)
							self.severityLevel = level
						},
						setTimeframe(
							timeframe:
								| "oneHour"
								| "sixHours"
								| "oneDay"
								| "sevenDays",
						) {
							Fathom.trackEvent(
								"Pipeline:Logs Filter:Timeframe Set",
							)
							Fathom.trackEvent(
								`Pipeline:Logs Filter:Timeframe Set=${timeframe}`,
							)
							self.timeframe = timeframe
						},
					}
				}),
			{},
		),
	})
	.actions((self) => {
		const getLogs = flow(function* (options: {
			fetchingStyle:
				| "filterChange"
				| "navigation"
				| "nextPage"
				| "autoRefresh"
				| "forcedRefresh"
			pageSize?: number
		}) {
			if (self.pipeline === undefined) {
				return
			}

			options.pageSize ??= 15

			let startTime: string | undefined
			let endTimeTimestamp: number

			if (options.fetchingStyle === "nextPage") {
				if (self.cursor === undefined || self.cursor.cursor === "") {
					return
				} else {
					endTimeTimestamp = self.cursor.originalCursorTime
				}
			} else if (
				options.fetchingStyle === "autoRefresh" ||
				options.fetchingStyle === "forcedRefresh"
			) {
				self.cursor = undefined
				endTimeTimestamp = Date.now()
			} else {
				self.cursor = undefined
				self.logs = cast([])
				endTimeTimestamp = Date.now()
			}

			if (options.fetchingStyle === "forcedRefresh") {
				self.lastFetchWasForced = true
			} else {
				self.lastFetchWasForced = false
			}

			// #region Date Logic
			if (self.filters.timeframe === "oneHour") {
				startTime = new Date(
					endTimeTimestamp - 1000 * 60 * 60,
				).toISOString()
			} else if (self.filters.timeframe === "sixHours") {
				startTime = new Date(
					endTimeTimestamp - 1000 * 60 * 60 * 6,
				).toISOString()
			} else if (self.filters.timeframe === "oneDay") {
				startTime = new Date(
					endTimeTimestamp - 1000 * 60 * 60 * 24,
				).toISOString()
			} else {
				startTime = new Date(
					endTimeTimestamp - 1000 * 60 * 60 * 24 * 7,
				).toISOString()
			}
			// #endregion

			self.fetch = {
				isFetching: true,
				fetchingStyle:
					options.fetchingStyle === "forcedRefresh"
						? "autoRefresh"
						: options.fetchingStyle,
			}

			let result: Awaited<ReturnType<typeof api.getFunctionLogs>> =
				yield api.getFunctionLogs({
					queries: {
						page_size: options.pageSize,
						page_token: self.cursor?.cursor,
						severity_code: self.filters.severityLevel,
						start_time: startTime,
						end_time: new Date(endTimeTimestamp).toISOString(),
					},
					params: {
						pipeline_id: self.pipeline.id,
					},
				})

			if (self.cursor === undefined) {
				self.logs = cast([])
				self.cursor = {
					cursor: result.next,
					originalCursorTime: endTimeTimestamp,
				}
			} else {
				self.cursor.cursor = result.next
			}

			if (options.fetchingStyle === "autoRefresh") {
				self.logs = cast(result.logs.reverse())
			} else {
				self.logs = cast([...result.logs.reverse(), ...self.logs])
			}

			self.lastFetch = Date.now()

			self.fetch = { isFetching: false }
		})

		return {
			getLogs,
			showLogFullScreen(logIndex: number) {
				Fathom.trackEvent("Pipeline:Log Enter Fullscreen")
				self.fullscreenLogIndex = logIndex
				document.body.classList.add("preventBodyScroll")
			},
			closeFullScreen() {
				document.body.classList.remove("preventBodyScroll")
				self.fullscreenLogIndex = undefined
			},
		}
	})
	.views((self) => {
		return {
			get hasNoLogs() {
				return self.logs.length === 0
			},
			get hasLoadedFinalPage() {
				return self.cursor && self.cursor.cursor === ""
			},
			get hasMorePages() {
				return self.cursor && self.cursor.cursor !== ""
			},
		}
	})

// #endregion

// #region PipelineDetailPage

export const PipelineDetailPage = types
	.model("PipelineDetailPage", {
		pipeline: types.maybe(PipelineModel),
		accessTokens: types.optional(AccessTokensModel, {}),
		logs: types.optional(LogsModel, {}),
		transformer: types.optional(TransformerModel, {}),
		isSaving: types.optional(types.boolean, false),
		isTestingEvent: types.optional(types.boolean, false),
		status: types.optional(types.string, "none"),
	})
	.actions((self) => {
		return {
			setStatus: (status: string) => {
				self.status = status
			},

			setEnvVariables: (envs?: Array<EnvironmentVariable>) => {
				if (self.pipeline?.environments) {
					self.pipeline.environments = cast(envs)
				} else {
					if (self.pipeline) self.pipeline.environments = cast(envs)
				}
			},

			resetPage: () => {
				self.pipeline = undefined
				self.status = "none"
				self.isSaving = false
				self.transformer.form.testEvent.hasError = false
				self.transformer.form.testEvent.errorMessage = ""
				self.transformer.form.setTestEventResponse("") // Reseting the old event response when user change the func
				self.transformer.form.testEvent.value = ""
			},

			getPipeline: flow(function* (options: {
				pipelineId: string
			}) {
				let pipeline: Awaited<ReturnType<typeof api.getPipeline>> =
					yield api.getPipeline({
						params: {
							pipeline_id: options.pipelineId,
						},
					})

				self.accessTokens.pipeline = undefined
				self.logs.pipeline = undefined

				self.pipeline = cast(pipeline)

				if (self.pipeline) {
					self.accessTokens.pipeline = self.pipeline
					self.accessTokens.getTokens()

					self.transformer.fetchSourceFiles(self.pipeline.id)

					if (self.pipeline.environments !== null) {
						self.transformer.form.setEnvironmentVariables(
							getSnapshot(self.pipeline.environments),
						)
						self.transformer.form.setCurrentEnvironmentVariables(
							getSnapshot(self.pipeline.environments),
						)
					} else {
						self.transformer.form.setEnvironmentVariables(null)
						self.transformer.form.setCurrentEnvironmentVariables(
							null,
						)
					}

					self.logs.pipeline = self.pipeline
					self.logs.filters = cast({})
					self.logs.getLogs({ fetchingStyle: "navigation" })
				}
			}),

			saveTransformer: flow(function* (callback: () => Promise<void>) {
				if (self.pipeline?.id) {
					self.transformer.form.validate()

					if (!self.transformer.form.hasError) {
						self.isSaving = true

						const result: Awaited<
							ReturnType<typeof api.uploadFunctionArtifacts>
						> = yield api.uploadFunctionArtifacts(
							{
								file: new File(
									[self.transformer.form.handler.value],
									"handler.py",
									{
										type: "text/plain",
									},
								),
								...(self.transformer.form.requirements
									.value && {
									requirementsTxt: new File(
										[
											self.transformer.form.requirements
												.value,
										],
										"requirements.txt",
										{
											type: "text/plain",
										},
									),
								}),
							},
							{
								params: {
									pipeline_id: self.pipeline.id,
								},
							},
						)

						self.transformer.form.setCurrentHandler(
							self.transformer.form.handler.value,
						)

						self.transformer.form.setCurrentRequirements(
							self.transformer.form.requirements.value,
						)

						self.transformer.form.setCurrentEnvironmentVariables(
							getSnapshot(
								self.transformer.form.environmentVariables,
							),
						)

						yield callback()

						//Reset Test Event func and Error
						pipelineDetailPageStore.transformer.form.testEvent.hasError = false
						pipelineDetailPageStore.transformer.form.testEvent.errorMessage =
							""
						pipelineDetailPageStore.transformer.form.setTestEventResponse(
							"",
						) // Reseting the old event response when user change the func
						//Reset Test Event func and Error

						self.isSaving = false

						return result
					}
				}
			}),

			callTestFunction: flow(function* (
				callback: (response: string) => Promise<void>,
				bodyPayload: Record<string, string> = {},
			) {
				if (!self.pipeline?.id) return

				self.isTestingEvent = true
				try {
					const response = yield api.testFunction(
						{
							...bodyPayload, // Request body payload
						},
						{
							params: {
								pipeline_id: self.pipeline.id, // Path parameter
							},
						},
					)
					self.isTestingEvent = false

					if (response.response === "") {
						// it means some error happens
						pipelineDetailPageStore.transformer.form.testEvent.hasError = true
						pipelineDetailPageStore.transformer.form.testEvent.errorMessage =
							response.error_details

						yield callback(response.stack_trace)
						return response.stack_trace
					} else {
						pipelineDetailPageStore.transformer.form.testEvent.hasError = false
						pipelineDetailPageStore.transformer.form.testEvent.errorMessage =
							""

						pipelineDetailPageStore.transformer.form.testEventResponse.hasError = false
						pipelineDetailPageStore.transformer.form.testEventResponse.errorMessage =
							""

						yield callback(response.response)
						return response.response
					}
				} catch (error) {
					self.isTestingEvent = false
				}
			}),

			deployPipeline: flow(function* (
				pipelineId: string,
			): Generator<Promise<typeof ResponseObj>> {
				try {
					self.isSaving = true
					let pipeline: Instance<typeof PipelineModel> =
						yield api.patchPipeline(
							{
								state: "running",
								metadata: {
									type: PipelineCreateType.Blank,
									sourceConnector:
										pipelineDetailPageStore.pipeline
											?.metadata?.sourceConnector,
									sinkConnector:
										pipelineDetailPageStore.pipeline
											?.metadata?.sinkConnector,
									steps_complete: true,
								},
							},
							{ params: { pipeline_id: pipelineId } },
						)

					if (pipeline) {
						router.push("PipelinesDetailDetails", {
							pipelineId: pipelineId,
						})
						self.pipeline = cast(pipeline)
						self.isSaving = false
						return pipeline
					}
				} catch (error) {
					self.isSaving = false
					return false
				}
				self.isSaving = false
			}),

			patchMetadata: flow(function* (
				pipelineId: string,
			): Generator<Promise<Instance<typeof ResponseObj>>> {
				try {
					let pipeline = yield api.patchPipeline(
						{
							metadata: {
								type: pipelineDetailPageStore.pipeline?.metadata
									?.type,
								sourceConnector:
									pipelineDetailPageStore.pipeline?.metadata
										?.sourceConnector,
								sinkConnector:
									pipelineDetailPageStore.pipeline?.metadata
										?.sinkConnector,
								step_transform: true,
								step_source:
									pipelineDetailPageStore.pipeline?.metadata
										?.step_source,
								step_sink:
									pipelineDetailPageStore.pipeline?.metadata
										?.step_sink,
								steps_complete:
									pipelineDetailPageStore.pipeline?.metadata
										?.steps_complete,
							},
						},
						{ params: { pipeline_id: pipelineId } },
					)
					self.pipeline = cast(pipeline)
					return pipeline
				} catch (error) {
					return false
				}
			}),

			patchSourceAndSink: flow(function* (
				pipelineId: string,
				type: string,
			): Generator<Promise<typeof ResponseObj>> {
				self.isSaving = true
				const isSource = type === "source"
				const form = isSource
					? pipelineCreatePageStore.sourceForm
					: pipelineCreatePageStore.sinkForm

				form.validate()
				try {
					const metadata = {
						...(isSource
							? {
									sourceConnector: {
										type: pipelineCreatePageStore.sourceForm
											.connector.type,
									},
									sinkConnector:
										pipelineDetailPageStore.pipeline
											?.metadata?.sinkConnector,
								}
							: {
									sourceConnector:
										pipelineDetailPageStore.pipeline
											?.metadata?.sourceConnector,
									sinkConnector: {
										type: pipelineCreatePageStore.sinkForm
											.connector.type,
									},
								}),
						type: pipelineDetailPageStore.pipeline?.metadata?.type,
						step_transform:
							pipelineDetailPageStore.pipeline?.metadata
								?.step_transform,
						step_source: isSource
							? true
							: pipelineDetailPageStore.pipeline?.metadata
									?.step_source,
						step_sink: isSource
							? pipelineDetailPageStore.pipeline?.metadata
									?.step_sink
							: true,
						steps_complete:
							pipelineDetailPageStore.pipeline?.metadata
								?.steps_complete,
					}
					let connector = {}
					if (isSource) {
						connector = {
							...(pipelineCreatePageStore.sourceForm.connector
								.type === "amazon_sqs"
								? {
										source_connector: {
											kind: "amazon_sqs",
											config: {
												queue_url:
													pipelineCreatePageStore
														.sourceForm.connector
														.meta.queueUrl.value,
												aws_region:
													pipelineCreatePageStore
														.sourceForm.connector
														.meta.region.value,
												aws_access_key:
													pipelineCreatePageStore
														.sourceForm.connector
														.meta.accessKey.value,
												aws_secret_key:
													pipelineCreatePageStore
														.sourceForm.connector
														.meta.secretKey.value,
											},
										},
									}
								: pipelineCreatePageStore.sourceForm.connector
											.type === "google_pubsub"
									? {
											source_connector: {
												kind: "google_pubsub",
												config: {
													project_id:
														pipelineCreatePageStore
															.sourceForm
															.connector.meta
															.projectId.value,
													subscription_id:
														pipelineCreatePageStore
															.sourceForm
															.connector.meta
															.subscriptionId
															.value,
													credentials_json:
														pipelineCreatePageStore
															.sourceForm
															.connector.meta
															.credentials.value,
												},
											},
										}
									: pipelineCreatePageStore.sourceForm
												.connector.type === "postgres"
										? {
												source_connector: {
													kind: "postgres",
													config: {
														db_host:
															pipelineCreatePageStore
																.sourceForm
																.connector.meta
																.host.value,
														db_port:
															pipelineCreatePageStore
																.sourceForm
																.connector.meta
																.port?.value ||
															"5432",
														db_user:
															pipelineCreatePageStore
																.sourceForm
																.connector.meta
																.username.value,
														db_pass:
															pipelineCreatePageStore
																.sourceForm
																.connector.meta
																.password.value,
														db_name:
															pipelineCreatePageStore
																.sourceForm
																.connector.meta
																.database_name
																.value,
														replication_slot:
															pipelineCreatePageStore
																.sourceForm
																.connector.meta
																.replication_slot
																.value,
														// replication_output_plugin_args:
														// 	pipelineCreatePageStore.sourceForm.connector.meta.replication_output_plugin_args.map(
														// 		(arg: string) =>
														// 			arg,
														// 	),
													},
												},
											}
										: {
												source_connector: null,
											}),
						}
					} else {
						connector = {
							...(pipelineCreatePageStore.sinkForm.connector
								.type === "webhook"
								? {
										sink_connector: {
											kind: "webhook",
											config: {
												url: pipelineCreatePageStore
													.sinkForm.connector.meta.url
													.value,
												method: pipelineCreatePageStore
													.sinkForm.connector.meta
													.method,
												headers: (<
													Array<{
														name: string
														value: string
													}>
												>pipelineCreatePageStore
													.sinkForm
													.connector
													.meta
													.headers).filter(
													({ name, value }) =>
														name !== "" &&
														value !== "",
												),
											},
										},
									}
								: pipelineCreatePageStore.sinkForm.connector
											.type === "clickhouse"
									? {
											sink_connector: {
												kind: "clickhouse",
												config: {
													addr: pipelineCreatePageStore
														.sinkForm.connector.meta
														.addr.value,
													database:
														pipelineCreatePageStore
															.sinkForm.connector
															.meta.database
															.value,
													table: pipelineCreatePageStore
														.sinkForm.connector.meta
														.table.value,
													username:
														pipelineCreatePageStore
															.sinkForm.connector
															.meta.username
															.value,
													password:
														pipelineCreatePageStore
															.sinkForm.connector
															.meta.password
															.value,
												},
											},
										}
									: pipelineCreatePageStore.sinkForm.connector
												.type === "amazon_s3"
										? {
												sink_connector: {
													kind: "amazon_s3",
													config: {
														s3_bucket:
															pipelineCreatePageStore
																.sinkForm
																.connector.meta
																.s3_bucket
																.value,
														s3_key: pipelineCreatePageStore
															.sinkForm.connector
															.meta.s3_key.value,
														aws_region:
															pipelineCreatePageStore
																.sinkForm
																.connector.meta
																.region.value,
														aws_access_key:
															pipelineCreatePageStore
																.sinkForm
																.connector.meta
																.accessKey
																.value,
														aws_secret_key:
															pipelineCreatePageStore
																.sinkForm
																.connector.meta
																.secretKey
																.value,
													},
												},
											}
										: pipelineCreatePageStore.sinkForm
													.connector.type ===
												"snowflake_cdc_json"
											? {
													sink_connector: {
														kind: "snowflake_cdc_json",
														config: {
															account:
																pipelineCreatePageStore
																	.sinkForm
																	.connector
																	.meta
																	.account
																	.value,
															warehouse:
																pipelineCreatePageStore
																	.sinkForm
																	.connector
																	.meta
																	.warehouse
																	.value,
															db_user:
																pipelineCreatePageStore
																	.sinkForm
																	.connector
																	.meta
																	.db_user
																	.value,
															db_pass:
																pipelineCreatePageStore
																	.sinkForm
																	.connector
																	.meta
																	.db_pass
																	.value,
															db_name:
																pipelineCreatePageStore
																	.sinkForm
																	.connector
																	.meta
																	.db_name
																	.value,
															db_schema:
																pipelineCreatePageStore
																	.sinkForm
																	.connector
																	.meta
																	.db_schema
																	.value,
															db_role:
																pipelineCreatePageStore
																	.sinkForm
																	.connector
																	.meta
																	.db_role,
														},
													},
												}
											: pipelineCreatePageStore.sinkForm
														.connector.type ===
													"pinecone_json"
												? {
														sink_connector: {
															kind: "pinecone_json",
															config: {
																api_key:
																	pipelineCreatePageStore
																		.sinkForm
																		.connector
																		.meta
																		.api_key
																		.value,
																api_host:
																	pipelineCreatePageStore
																		.sinkForm
																		.connector
																		.meta
																		.api_host
																		.value,
																index_host:
																	pipelineCreatePageStore
																		.sinkForm
																		.connector
																		.meta
																		.index_host
																		.value,
																api_source_tag:
																	pipelineCreatePageStore
																		.sinkForm
																		.connector
																		.meta
																		.api_source_tag,
																// client_headers:
																// 	pipelineCreatePageStore
																// 		.sinkForm
																// 		.connector
																// 		.meta
																// 		.client_headers,
															},
														},
													}
												: {
														sink_connector: null,
													}),
						}
					}
					let pipeline = yield api.patchPipeline(
						{
							metadata,
							...connector,
						},
						{ params: { pipeline_id: pipelineId } },
					)

					self.pipeline = cast(pipeline)
					pipelineDetailPageStore.isSaving = false
					return pipeline
				} catch (error) {
					pipelineDetailPageStore.isSaving = false
					return false
				}
			}),

			refreshPipeline: flow(function* () {
				if (self.pipeline?.id) {
					let pipeline: Awaited<ReturnType<typeof api.getPipeline>> =
						yield api.getPipeline({
							params: {
								pipeline_id: self.pipeline.id,
							},
						})

					self.pipeline = cast(pipeline)
				}
			}),
		}
	})
	.views((self) => {
		return {
			get viewOnlyMode(): boolean {
				return !!self.pipeline?.metadata?.view_only
			},

			get sourceConnectorType():
				| "sdk"
				| "webhook"
				| "amazon_sqs"
				| "google_pubsub"
				| "postgres" {
				if (self.pipeline?.metadata?.sourceConnector?.type) {
					return self.pipeline.metadata.sourceConnector.type
				} else if (self.pipeline?.source_connector?.kind) {
					return self.pipeline.source_connector.kind
				} else {
					return "sdk"
				}
			},

			get sinkConnectorType():
				| "sdk"
				| "webhook"
				| "clickhouse"
				| "amazon_s3"
				| "snowflake_cdc_json"
				| "pinecone_json" {
				if (self.pipeline?.metadata?.sinkConnector?.type) {
					return self.pipeline.metadata.sinkConnector.type
				} else if (self.pipeline?.sink_connector?.kind)
					return self.pipeline?.sink_connector?.kind
				else {
					return "sdk"
				}
			},

			get sourceConnectorValues():
				| {
						queue_url: string
						aws_region: string
						aws_access_key: string
						aws_secret_key: string
				  }
				| {
						project_id: string
						subscription_id: string
						credentials_json: string
				  }
				| {
						db_host: string
						db_port: string
						db_user: string
						db_pass: string
						db_name: string
						replication_slot: string
						// replication_output_plugin_args: string[]
				  }
				| undefined {
				if (self.pipeline?.source_connector?.kind === "amazon_sqs") {
					return {
						queue_url:
							self.pipeline.source_connector.config.queue_url ||
							"",
						aws_region:
							self.pipeline.source_connector.config.aws_region ||
							"",
						aws_access_key:
							self.pipeline.source_connector.config
								.aws_access_key || "",
						aws_secret_key: "",
					}
				} else if (
					self.pipeline?.source_connector?.kind === "google_pubsub"
				) {
					return {
						project_id:
							self.pipeline.source_connector.config.project_id ||
							"",
						subscription_id:
							self.pipeline.source_connector.config
								.subscription_id || "",
						credentials_json:
							self.pipeline.source_connector.config
								.credentials_json || "",
					}
				} else if (
					self.pipeline?.source_connector?.kind === "postgres"
				) {
					return {
						db_host:
							self.pipeline.source_connector.config.db_host || "",
						db_port:
							self.pipeline.source_connector.config.db_port || "",
						db_user:
							self.pipeline.source_connector.config.db_user || "",
						db_pass: "",
						db_name:
							self.pipeline.source_connector.config.db_name || "",
						replication_slot:
							self.pipeline.source_connector.config
								.replication_slot || "",
						// replication_output_plugin_args:
						// 	self.pipeline.source_connector.config
						// 		.replication_output_plugin_args || [],
					}
				}
				return undefined // Explicitly handle cases where the kind isn't "amazon_sqs"
			},

			get sinkConnectorValues():
				| {
						addr: string
						database: string
						username: string
						password: string
						table: string
				  }
				| {
						s3_bucket: string
						s3_key: string
						aws_region: string
						aws_access_key: string
						aws_secret_key: string
				  }
				| {
						account: string
						warehouse: string
						db_user: string
						db_pass: string
						db_name: string
						db_schema: string
						// db_host: string
						// db_port: string
						db_role: string
				  }
				| {
						api_key: string
						api_host: string
						index_host: string
						api_source_tag: string
						client_headers?: string
				  }
				| {
						url: string
						method: string
						headers: Record<string, string>
				  }
				| undefined {
				if (self.pipeline?.sink_connector?.kind === "clickhouse") {
					return {
						addr: self.pipeline.sink_connector.config.addr || "",
						database:
							self.pipeline.sink_connector.config.database || "",
						username:
							self.pipeline.sink_connector.config.username || "",
						password: "",
						table: self.pipeline.sink_connector.config.table || "",
					}
				} else if (
					self.pipeline?.sink_connector?.kind === "amazon_s3"
				) {
					return {
						s3_bucket:
							self.pipeline.sink_connector.config.s3_bucket || "",
						s3_key:
							self.pipeline.sink_connector.config.s3_key || "",
						aws_region:
							self.pipeline.sink_connector.config.aws_region ||
							"",
						aws_access_key:
							self.pipeline.sink_connector.config
								.aws_access_key || "",
						aws_secret_key: "",
					}
				} else if (self.pipeline?.sink_connector?.kind === "webhook") {
					const rawHeaders =
						self.pipeline.sink_connector.config.headers || {}
					const headers: Record<string, string> = Array.isArray(
						rawHeaders,
					)
						? rawHeaders.reduce(
								(acc, { name, value }) => {
									acc[name] = value
									return acc
								},
								{} as Record<string, string>,
							)
						: rawHeaders
					return {
						url: self.pipeline.sink_connector.config.url || "",
						method:
							self.pipeline.sink_connector.config.method ||
							"POST",
						headers,
					}
				} else if (
					self.pipeline?.sink_connector?.kind === "snowflake_cdc_json"
				) {
					return {
						account:
							self.pipeline.sink_connector.config.account || "",
						warehouse:
							self.pipeline.sink_connector.config.warehouse || "",
						db_user:
							self.pipeline.sink_connector.config.db_user || "",
						db_pass: "",
						db_name:
							self.pipeline.sink_connector.config.db_name || "",
						db_schema:
							self.pipeline.sink_connector.config.db_schema || "",
						// db_host:
						// 	self.pipeline.sink_connector.config.db_host || "",
						// db_port:
						// 	self.pipeline.sink_connector.config.db_port ||
						// 	"443",
						db_role:
							self.pipeline.sink_connector.config.db_role || "",
					}
				} else if (
					self.pipeline?.sink_connector?.kind === "pinecone_json"
				) {
					return {
						api_key:
							self.pipeline.sink_connector.config.api_key || "",
						api_host:
							self.pipeline.sink_connector.config.api_host || "",
						index_host:
							self.pipeline.sink_connector.config.index_host ||
							"",
						api_source_tag:
							self.pipeline.sink_connector.config
								.api_source_tag || "",
						// client_headers:
						// 	self.pipeline.sink_connector.config
						// 		.client_headers || "",
					}
				}
				return undefined // Explicitly handle cases where the kind isn't recognized.
			},
		}
	})

// #endregion

export const pipelineDetailPageStore = PipelineDetailPage.create()
