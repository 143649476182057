import { AnimatePresence, m } from "framer-motion"
import { observer } from "mobx-react"
import {
	Suspense,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "preact/compat"
import { Check, Edit2, Eye, Pause, Play, Trash } from "react-feather"
import {
	Badge,
	Button,
	HiddenVisually,
	Icon,
	Loader,
	Skeleton,
	Tabs,
	Text,
	Tooltip,
	View,
	useToast,
} from "reshaped"
import { P, match } from "ts-pattern"
import css from "./detailsPage.module.css"

import { AceEditor } from "#components/AceEditor"
import { modalsStore } from "#modals"
import { router } from "#router"

import { pipelineDetailPageStore } from "./page.model"

import { PipelineCreateType, PipelineDetailSectionsType } from "../../enums"
import { AccessTokens } from "./components/AccessTokens"
import { Details } from "./components/Details"
import { Logs } from "./components/Logs"
import { TransformerTest } from "./components/TransformerTest"
import { DataSink, SinkOptions } from "./tabs/dataSink"
import { DataSource, SourceOptions } from "./tabs/dataSource"

const Header = observer(({ onDismissTabs }: { onDismissTabs: () => void }) => {
	const toast = useToast()
	const ShowToast = () => {
		const id = toast.show({
			color: "positive",
			timeout: 5000,
			icon: Check,
			title: `"${pipelineDetailPageStore.pipeline?.name}" deployed successfully.`,
			actionsSlot: (
				<Button
					variant="faded"
					color="inherit"
					onClick={() => toast.hide(id)}
				>
					Dismiss
				</Button>
			),
		})
	}

	const deployBtn = (
		<Button
			className={css.button}
			color="primary"
			as="a"
			attributes={{ target: "_blank" }}
			onClick={async () => {
				await pipelineDetailPageStore.deployPipeline(
					pipelineDetailPageStore.pipeline?.id || "undefined",
				)
				onDismissTabs()
				ShowToast()
				pipelineDetailPageStore.setStatus("running")
			}}
			disabled={
				pipelineDetailPageStore.viewOnlyMode ||
				pipelineDetailPageStore.isSaving ||
				pipelineDetailPageStore.status !== "deployable"
			}
		>
			Deploy Pipeline
		</Button>
	)

	const deleteBtn = (
		<Button
			icon={Trash}
			variant="outline"
			disabled={pipelineDetailPageStore.viewOnlyMode}
			onClick={() => {
				let pipeline = pipelineDetailPageStore.pipeline
				if (pipeline) {
					modalsStore.deletePipeline.openModal(
						pipeline.id,
						pipeline.name,
						async () => {
							router.push("PipelinesList")
						},
					)
				}
			}}
		>
			Delete
		</Button>
	)

	const renameBtn = (
		<Button
			variant="outline"
			icon={Edit2}
			disabled={pipelineDetailPageStore.viewOnlyMode}
			onClick={() => {
				let pipeline = pipelineDetailPageStore.pipeline
				if (pipeline) {
					modalsStore.renamePipeline.openModal(
						pipeline.id,
						pipeline.name,
						async () => {
							pipelineDetailPageStore.refreshPipeline()
						},
					)
				}
			}}
		>
			Rename
		</Button>
	)

	const playPauseBtn = pipelineDetailPageStore.pipeline?.isRunning ? (
		<Button
			icon={Pause}
			color="neutral"
			disabled={pipelineDetailPageStore.viewOnlyMode}
			onClick={() => {
				let pipeline = pipelineDetailPageStore.pipeline
				if (pipeline) {
					pipeline.pause()
				}
			}}
		>
			Pause
		</Button>
	) : (
		<Button
			icon={Play}
			disabled={pipelineDetailPageStore.viewOnlyMode}
			color="positive"
			onClick={() => {
				let pipeline = pipelineDetailPageStore.pipeline
				if (pipeline) {
					pipeline.resume()
				}
			}}
		>
			Resume
		</Button>
	)

	return (
		<View direction="column">
			<View
				direction="row"
				gap={1}
				align="center"
				justify="space-between"
			>
				{
					<View direction="row" gap={4} align="center">
						{pipelineDetailPageStore.pipeline ? (
							<>
								{pipelineDetailPageStore.status ===
									"deployable" && (
									<Text variant="title-3" weight="medium">
										Review and deploy
									</Text>
								)}
								<Text variant="title-2" weight="medium">
									{isPipelineCompletedAndRunning() &&
										pipelineDetailPageStore.pipeline.name}
								</Text>

								{isPipelineCompletedAndRunning() &&
									(pipelineDetailPageStore.pipeline
										.isRunning ? (
										<Badge color="positive" variant="faded">
											Active
										</Badge>
									) : (
										<Badge color="neutral" variant="faded">
											Paused
										</Badge>
									))}
							</>
						) : (
							<View paddingTop={2}>
								<Skeleton width={50} height={9} />
							</View>
						)}
					</View>
				}
				{pipelineDetailPageStore.status === "deployable" && deployBtn}
				{isPipelineCompletedAndRunning() && (
					<div style={{ display: "flex", gap: "12px" }}>
						{" "}
						{deleteBtn} {renameBtn} {playPauseBtn}{" "}
					</div>
				)}
			</View>
			{pipelineDetailPageStore.status === "deployable" && (
				<>
					<Text variant="body-3" weight="medium">
						Review your pipeline, edit if necessary, and deploy to
						production
					</Text>
					<View paddingBottom={4} />
				</>
			)}
		</View>
	)
})

const TabPanel: React.FunctionComponent<{ key: string }> = observer((props) => {
	return (
		<m.div
			key={props.key}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.4 }}
		>
			{props.children}
		</m.div>
	)
})

const isPipelineCompletedAndRunning = () => {
	if (
		pipelineDetailPageStore.pipeline?.metadata?.steps_complete !== false ||
		pipelineDetailPageStore.pipeline?.metadata?.type ===
			PipelineCreateType.Blank ||
		pipelineDetailPageStore.pipeline?.isRunning ||
		pipelineDetailPageStore.status === "running"
	)
		return true
	else return false
}

export const PipelineDetail = observer(() => {
	const toast = useToast()
	const [selectedSource, setSelectedSource] = useState(
		SourceOptions.find((option) => option.value === "undefined"),
	)
	const [selectedSink, setSelectedSink] = useState(
		SinkOptions.find((option) => option.value === "undefined"),
	)
	const [selectedTransform, setSelectedTransform] = useState(false)
	const [moveToNextScreen, setMoveToNextScreen] = useState(true)

	const routes = router.useRoute([
		"PipelinesDetailDetails",
		"PipelinesDetailTransformer",
		"PipelinesDetailTokens",
		"PipelinesDetailLogs",
	])

	const selectedTab = match(routes)
		.with(router.P.PipelinesDetailTokens(P._), () => "tokens")
		.with(router.P.PipelinesDetailLogs(P._), () => "logs")
		.with(router.P.PipelinesDetailTransformer(P._), () => "transformer")
		.otherwise(() => "details")

	const sourceRef = useRef<HTMLDivElement | null>()
	const transformRef = useRef<HTMLDivElement | null>()
	const sinkRef = useRef<HTMLDivElement | null>()

	const [isAnimating, setIsAnimating] = useState(false)
	const [activeLink, setActiveLink] = useState<string | null>(null)

	let pipelineID = ""
	if (pipelineDetailPageStore.pipeline?.id)
		pipelineID = pipelineDetailPageStore.pipeline.id

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (pipelineDetailPageStore.pipeline?.id) {
			if (
				pipelineDetailPageStore.pipeline?.metadata?.step_transform ===
					true ||
				isPipelineCompletedAndRunning()
			)
				setSelectedTransform(true)
			if (
				pipelineDetailPageStore.pipeline?.metadata?.step_source ===
					true ||
				isPipelineCompletedAndRunning()
			)
				setSelectedSource(
					SourceOptions.find(
						(option) =>
							option.value ===
							pipelineDetailPageStore.sourceConnectorType,
					),
				)
			if (
				pipelineDetailPageStore.pipeline?.metadata?.step_sink ===
					true ||
				isPipelineCompletedAndRunning()
			)
				setSelectedSink(
					SinkOptions.find(
						(option) =>
							option.value ===
							pipelineDetailPageStore.sinkConnectorType,
					),
				)
		}
	}, [pipelineDetailPageStore.pipeline])

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (moveToNextScreen === false) {
			// Special case for Transform Continue
			return
		}
		if (
			pipelineDetailPageStore.pipeline?.metadata?.step_transform === false
		) {
			setActiveLink(PipelineDetailSectionsType.Transform)
		} else if (
			pipelineDetailPageStore.pipeline?.metadata?.step_source === false
		) {
			setActiveLink(PipelineDetailSectionsType.Source)
		} else if (
			pipelineDetailPageStore.pipeline?.metadata?.step_sink === false
		) {
			setActiveLink(PipelineDetailSectionsType.Sink)
		} else if (canShowDeployButton()) {
			pipelineDetailPageStore.setStatus("deployable")
			setActiveLink(PipelineDetailSectionsType.Deploy)
		}
	}, [pipelineDetailPageStore.pipeline?.metadata, moveToNextScreen])

	function onTransformContinue() {
		if (moveToNextScreen === true) {
			if (
				pipelineDetailPageStore.pipeline?.metadata?.step_source ===
				false
			) {
				setActiveLink(PipelineDetailSectionsType.Source)
			} else if (
				pipelineDetailPageStore.pipeline?.metadata?.step_sink === false
			) {
				setActiveLink(PipelineDetailSectionsType.Sink)
			} else if (canShowDeployButton()) {
				pipelineDetailPageStore.setStatus("deployable")
				setActiveLink(PipelineDetailSectionsType.Deploy)
			}
		} else setMoveToNextScreen(true)
	}

	async function onSaveTransform() {
		if (
			pipelineDetailPageStore.pipeline?.metadata?.step_transform === false
		)
			setMoveToNextScreen(false)
		await pipelineDetailPageStore.transformer.form.validate()
		if (!pipelineDetailPageStore.transformer.form.hasError) {
			if (pipelineDetailPageStore.transformer.form.handler.value) {
				setSelectedTransform(true)
				await pipelineDetailPageStore.patchMetadata(pipelineID)
			}
		}
	}

	function onDiscard() {
		makeTabsActive(activeLink || "Deploy")
	}

	function onSaveSink() {
		setSelectedSink(
			SinkOptions.find(
				(option) =>
					option.value === pipelineDetailPageStore.sinkConnectorType,
			),
		)
		const id = toast.show({
			color: "neutral",
			timeout: 5000,
			icon: Check,
			title: "Sink saved successfully.",
			actionsSlot: (
				<Button
					variant="faded"
					color="inherit"
					onClick={() => toast.hide(id)}
				>
					Dismiss
				</Button>
			),
		})
		makeTabsActive(activeLink || "Deploy")
	}

	function onSaveSource() {
		setSelectedSource(
			SourceOptions.find(
				(option) =>
					option.value ===
					pipelineDetailPageStore.sourceConnectorType,
			),
		)
		const id = toast.show({
			color: "neutral",
			timeout: 5000,
			icon: Check,
			title: "Source saved successfully.",
			actionsSlot: (
				<Button
					variant="faded"
					color="inherit"
					onClick={() => toast.hide(id)}
				>
					Dismiss
				</Button>
			),
		})
		makeTabsActive(activeLink || "Deploy")
	}

	function isTransformStepCompleted() {
		if (pipelineDetailPageStore.pipeline?.metadata?.step_transform === true)
			return true
		else if (isPipelineCompletedAndRunning()) return true
		else return false
	}

	function makeTabsActive(name: string) {
		if (name === activeLink) {
			name = "Deploy"
		}
		// if (pipelineDetailPageStore.status !== 'running')
		// 	pipelineDetailPageStore.setStatus('none')

		setIsAnimating(true)
		switch (name) {
			case PipelineDetailSectionsType.Source:
				setMoveToNextScreen(true)
				setActiveLink(PipelineDetailSectionsType.Source)
				break
			case PipelineDetailSectionsType.Sink:
				setMoveToNextScreen(true)
				setActiveLink(PipelineDetailSectionsType.Sink)
				break
			case PipelineDetailSectionsType.Transform:
				setActiveLink(PipelineDetailSectionsType.Transform)
				break
			case PipelineDetailSectionsType.Deploy:
				if (canShowDeployButton())
					pipelineDetailPageStore.setStatus("deployable")
				setActiveLink("Deploy")
				break
			case PipelineDetailSectionsType.None:
				setActiveLink("None")
		}
	}

	const canShowDeployButton = () => {
		if (
			moveToNextScreen === true &&
			pipelineDetailPageStore.pipeline?.metadata?.step_transform ===
				true &&
			pipelineDetailPageStore.pipeline?.metadata?.step_sink === true &&
			pipelineDetailPageStore.pipeline?.metadata?.step_source === true &&
			pipelineDetailPageStore.status !== "running" &&
			!pipelineDetailPageStore.pipeline?.isRunning &&
			pipelineDetailPageStore.pipeline?.metadata?.steps_complete === false
		)
			return true
		else return false
	}

	const source_css = `${activeLink === PipelineDetailSectionsType.Source ? css.top_button_source_selected : css.top_button_source}`
	const source_css_small = `${activeLink === PipelineDetailSectionsType.Source ? css.top_button_source_selected_small : css.top_button_source_small}`

	const sink_css = `${activeLink === PipelineDetailSectionsType.Sink ? css.top_button_sink_selected : css.top_button_sink}`
	const sink_css_small = `${activeLink === PipelineDetailSectionsType.Sink ? css.top_button_sink_selected_small : css.top_button_sink_small}`

	const tranform_css = `${activeLink === PipelineDetailSectionsType.Transform ? css.top_button_transform_selected : css.top_button_transform}`
	const tranform_css_small = `${activeLink === PipelineDetailSectionsType.Transform ? css.top_button_transform_selected_small : css.top_button_transform_small}`

	const isDeployableOrRunning = () => {
		return isPipelineCompletedAndRunning() || canShowDeployButton()
	}
	const TopBars = (
		<View align="center" direction="row">
			<View gap={4} align="center" direction="row" divided width={"100%"}>
				<View
					className={css.container}
					direction="row"
					width={"100%"}
					justify={"space-between"}
				>
					<Button
						className={`${isDeployableOrRunning() ? source_css : source_css_small}`}
						as="a"
						variant="ghost"
						ref={sourceRef}
						onClick={() => {
							makeTabsActive(PipelineDetailSectionsType.Source)
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								gap: "3px",
							}}
						>
							{isDeployableOrRunning() ? (
								<>
									<Text
										variant={"body-3"}
										className={css.text_disabled}
									>
										Source
									</Text>
									<div
										style={{
											display: "flex",
											alignItems: "center",
										}}
									>
										{selectedSource?.icon && (
											<span
												style={{ marginRight: "8px" }}
											>
												{selectedSource.icon}
											</span>
										)}
										<Text
											variant={"body-1"}
											weight={"medium"}
										>
											{selectedSource?.name}
										</Text>
									</div>
								</>
							) : (
								<div style={{ display: "flex" }}>
									<Text variant={"body-3"}>Source</Text>
									{selectedSource?.name && (
										<Text
											variant={"body-3"}
											weight={"medium"}
										>
											{`: ${selectedSource?.name}`}
										</Text>
									)}
								</div>
							)}
						</div>
					</Button>
					<Button
						className={`${isDeployableOrRunning() ? tranform_css : tranform_css_small}`}
						variant="ghost"
						as="a"
						ref={transformRef}
						onClick={() =>
							makeTabsActive(PipelineDetailSectionsType.Transform)
						}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								gap: "3px",
							}}
						>
							{isDeployableOrRunning() ? (
								<>
									{" "}
									<Text
										variant={"body-3"}
										className={css.text_disabled}
									>
										Transform
									</Text>
									{selectedTransform && (
										<div
											style={{
												display: "flex",
												alignItems: "center",
											}}
										>
											<Text
												variant={"body-1"}
												weight={"medium"}
											>
												{"handler.py"}
											</Text>
										</div>
									)}
								</>
							) : (
								<div style={{ display: "flex" }}>
									<Text variant={"body-3"}>Transform</Text>
									{selectedTransform && (
										<Text
											variant={"body-3"}
											weight={"medium"}
										>
											{":  handler.py"}
										</Text>
									)}
								</div>
							)}
						</div>
					</Button>
					<Button
						className={`${isDeployableOrRunning() ? sink_css : sink_css_small}`}
						variant="ghost"
						as="a"
						ref={sinkRef}
						onClick={() =>
							makeTabsActive(PipelineDetailSectionsType.Sink)
						}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								gap: "3px",
							}}
						>
							{isDeployableOrRunning() ? (
								<>
									<Text
										variant={"body-3"}
										className={css.text_disabled}
									>
										Sink
									</Text>
									<div
										style={{
											display: "flex",
											alignItems: "center",
										}}
									>
										{selectedSink?.icon && (
											<span
												style={{ marginRight: "8px" }}
											>
												{selectedSink?.icon}
											</span>
										)}
										<Text
											variant={"body-1"}
											weight={"medium"}
										>
											{selectedSink?.name}
										</Text>
									</div>
								</>
							) : (
								<div style={{ display: "flex" }}>
									<Text variant={"body-3"}>Sink</Text>
									{selectedSink?.name && (
										<Text
											variant={"body-3"}
											weight={"medium"}
										>
											{`: ${selectedSink?.name}`}
										</Text>
									)}
								</div>
							)}
						</div>
					</Button>
				</View>
			</View>
		</View>
	)

	if (!pipelineDetailPageStore.pipeline?.id) return <Loader />

	const [logsExpand, setLogsExpand] = useState(false)
	const handleLogsExpand = (status: boolean) => {
		setLogsExpand(status)
	}

	const infoPanel = isPipelineCompletedAndRunning() && (
		<div className={css.card}>
			<View gap={3} paddingTop={1} padding={4}>
				<Tabs
					defaultValue={selectedTab}
					onChange={(event) => {
						if (pipelineDetailPageStore.pipeline) {
							let routeParameters = {
								pipelineId: pipelineDetailPageStore.pipeline.id,
								spaceId:
									pipelineDetailPageStore.pipeline.space_id,
							}

							match(event.value)
								.with("transformer", () => {
									history.pushState(
										{},
										"",
										router.PipelinesDetailTransformer(
											routeParameters,
										),
									)
								})
								.with("tokens", () => {
									history.pushState(
										{},
										"",
										router.PipelinesDetailTokens(
											routeParameters,
										),
									)
								})
								.with("logs", () => {
									history.pushState(
										{},
										"",
										router.PipelinesDetailLogs(
											routeParameters,
										),
									)
								})
								.otherwise(() => {
									history.pushState(
										{},
										"",
										router.PipelinesDetailDetails(
											routeParameters,
										),
									)
								})
						}
					}}
				>
					<Tabs.List>
						<Tabs.Item value="details">
							<View paddingInline={2}>Details</View>
						</Tabs.Item>
						<Tabs.Item value="tokens">
							<View paddingInline={2}>Access Tokens</View>
						</Tabs.Item>
						<Tabs.Item value="logs">
							<View paddingInline={2}>Logs</View>
						</Tabs.Item>
					</Tabs.List>
					<View paddingBlock={4}>
						<AnimatePresence mode="sync">
							<Tabs.Panel value="details" key="detailsParent">
								<TabPanel key="details">
									<Details />
								</TabPanel>
							</Tabs.Panel>
							<Tabs.Panel value="tokens" key="tokensParent">
								<TabPanel key="tokens">
									<AccessTokens />
								</TabPanel>
							</Tabs.Panel>
							<Tabs.Panel value="logs" key="logsParent">
								<TabPanel key="logs">
									<Logs onLogsExpand={handleLogsExpand} />
								</TabPanel>
							</Tabs.Panel>
						</AnimatePresence>
					</View>
				</Tabs>
			</View>
		</div>
	)

	const handleCloseTabs = () => {
		makeTabsActive(PipelineDetailSectionsType.None)
	}

	return (
		<View gap={9} className={css.container}>
			{pipelineDetailPageStore.viewOnlyMode && (
				<Tooltip
					text="This pipeline was created outside of the Web App. Therefore, you cannot make any changes here."
					position="bottom"
					id="viewOnly"
				>
					{(attributes) => (
						<View
							attributes={attributes}
							direction="row"
							gap={1.5}
							align="center"
							className={css.viewOnlyModeLabel}
						>
							<Icon size={4} svg={Eye} />
							View-Only
						</View>
					)}
				</Tooltip>
			)}
			<Header onDismissTabs={handleCloseTabs} />
			<HiddenVisually>
				<Suspense fallback={null}>
					<AceEditor mode="python" />
				</Suspense>
			</HiddenVisually>
			{!logsExpand && TopBars}
			{pipelineDetailPageStore.pipeline?.id ? (
				<>
					{
						<>
							{activeLink ===
								PipelineDetailSectionsType.Source && (
								<div
									className={`${isAnimating ? css.slide_up : css.slide_down} ${isDeployableOrRunning() ? css.card : ""}`}
									style={{ position: "relative" }}
								>
									<View
										padding={
											isDeployableOrRunning() ? 4 : 0
										}
									>
										<DataSource
											isEdit={isDeployableOrRunning()}
											onDiscard={onDiscard}
											onSave={onSaveSource}
										/>
									</View>
								</div>
							)}
							{activeLink ===
								PipelineDetailSectionsType.Transform && (
								<div
									className={`${isAnimating ? css.slide_up : css.slide_down} ${isDeployableOrRunning() ? css.card : ""}`}
									style={{ position: "relative" }}
								>
									<View
										padding={
											isDeployableOrRunning() ? 4 : 0
										}
									>
										<TransformerTest
											isTransformStepComplete={isTransformStepCompleted()}
											isEdit={isDeployableOrRunning()}
											onDiscard={onDiscard}
											onSave={onSaveTransform}
											onContinue={onTransformContinue}
										/>
									</View>
								</div>
							)}
							{activeLink === PipelineDetailSectionsType.Sink && (
								<div
									className={`${isAnimating ? css.slide_up : css.slide_down} ${isDeployableOrRunning() ? css.card : ""}`}
									style={{ position: "relative" }}
								>
									<View
										padding={
											isDeployableOrRunning() ? 4 : 0
										}
									>
										<DataSink
											isEdit={isDeployableOrRunning()}
											onDiscard={onDiscard}
											onSave={onSaveSink}
										/>
									</View>
								</div>
							)}
						</>
					}

					{infoPanel}
				</>
			) : (
				<Loader />
			)}
		</View>
	)
})
